<template>
  <div>

    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Change Redirect Link</v-card-title>
        <v-card-text>
          <v-text-field v-model="newRedirectLink" label="New Redirect Link"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateRedirectLink">Update</v-btn>
          <v-btn @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  data() {
    return {
      dialog: false,
      newRedirectLink: '',
      scannedQrCodeId : null,
      user:{}
    }
  },
  components: {
    StreamBarcodeReader,
  },
    created() {
    // Retrieve user data and access token from local storage
    const userData = JSON.parse(localStorage.getItem('user'));
    const accessToken = localStorage.getItem('accessToken');

    // Set user data in the component data
    this.user = userData;
  },
  methods: {
     updateRedirectLink(qrCodeId, userId, newRedirectLink) {
      // Call the API endpoint to update the redirect link
      axios.post('https://api.zuzy.in/api/update-redirect', {
        qrcodeId: this.scannedQrCodeId,
        userId: this.user._id,
        redirectLink: this.newRedirectLink
      })
      .then(response => {
        if (response.data.success) {
          // If the update operation was successful, do something (e.g., display a success message)
          console.log('Redirect link updated successfully');
           this.dialog = false;
           this.scannedQrCodeId = null;
           this.newRedirectLinkId = null;
        this.$router.push('/dashboard');

        } else {
          // If the update operation failed, handle the error (e.g., display an error message)
          console.error('Failed to update redirect link:', response.data.error);
            this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'QR code is already registered. Please contact 7016241044 for assistance.'
          });
          this.dialog = false;
           this.scannedQrCodeId = null;
           this.newRedirectLinkId = null;
        this.$router.push('/dashboard');
        }
      })
      .catch(error => {
        // Handle API call errors (e.g., network error)
        console.error('Error updating redirect link:', error);
      });
    },
    closeDialog() {
      // Close the dialog box without updating the redirect link
      this.dialog = false;
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`)
      axios
        .post('https://api.zuzy.in/api/register', {
          scannedUrl: text, // Assuming decodedValue contains the scanned URL
        })
        .then(response => {
          // Handle the API response
          const { success } = response.data
          if (success) {
            // QR code exists and is inactive
            console.log(response.data);
            console.log('QR code exists and is inactive',response.data.qrCodeId)
            this.scannedQrCodeId = response.data.qrCodeId;
             this.dialog = true;
            // Perform further actions as needed
          } else {
            // QR code does not exist or is active
            console.log('QR code does not exist or is active')
            // Perform further actions as needed
          }
        })
        .catch(error => {
          // Handle errors
          console.error('Error:', error)
          // Display an error message to the user or retry the request
        })
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`)
    },
  },
}
</script>
